$(document).ready(function () {
	lazyloadFun();
	setBackgroundElement();

	// header
	header.init();
	buttonChatbox.init();
	scrollNavSticky.init();
	// height('.home-4-section .comment-item .caption');
	// swiper
	swiperInit();
	scrollYTabsletMb();

	staffCookie();
	checkStaffCookie();
	// twentytwenty
	$(".twentytwenty-container[data-orientation!='vertical']").twentytwenty({
		default_offset_pct: 0.5,
	});

	loadProduct();
	loadGallery();

	//toggle Faqs
	toggleFaqs();

	// form clone Title Page to form
	getTitlePage();
	autoCheck();

	changeMaps();

	moveHtml.init();

	height('.construction-design-detail-5-section .construction-design-item');
	height('.about-7-section .staff-item .caption');


	addScriptScrollCustom();

	// Target your .container, .wrapper, .post, etc.
	// $('.full-content iframe[src*="youtube"]').fitVids();
	$('iframe[src*="youtube"]').parent().fitVids();

	bedroomInit();

});

var chatBoxFixed = $('.chatbox-fixed');
var maxTop = $('.subscribe').offset().top - chatBoxFixed.outerHeight();

$(window).on('scroll', function () {
	header.scrollActive();
	buttonChatbox.scrollShowBackToTop();
	scrollNavSticky.scrollCheckActive();

	if (chatBoxFixed.offset().top > maxTop) {
		chatBoxFixed.addClass('hide')
	} else {
		chatBoxFixed.removeClass('hide')
	}
});

$(document).on('resize', function () { });

function lazyloadFun() {

	var pictureObserver = lozad('.lozad-picture', {
		threshold: 0.1
	});

	var observer = lozad('.lozad', {
		threshold: 0.1,
		enableAutoReload: true,
		load: function (el) {
			el.src = el.getAttribute("data-src");
		}
	});
	var backgroundObserver = lozad('.lozad-background', {
		threshold: 0.1
	});

	pictureObserver.observe();
	observer.observe()
	backgroundObserver.observe();
}

function height(el) {
	var height = 0;
	$(el).each(function () {
		var thisHeight = $(this).height();
		if (thisHeight > height) {
			height = thisHeight;
		}
		setTimeout(() => {
			$(el).height(height);
		}, 100);
	});
}

let header = {
	scrollActive: function () {
		let height = $('header').height()
		if ($(window).scrollTop() > height) {
			$('header').addClass('active')
		} else {
			$('header').removeClass('active')
		}
	},
	mappingMenu: () => {
		let caption = $('header .header-wrap .left-wrap .company-caption').mapping({
			mobileWrapper: 'header .mobile-wrap',
			mobileMethod: 'appendTo',
			desktopWrapper: 'header .header-wrap .left-wrap .top',
			desktopMethod: 'appendTo',
			breakpoint: 1279.89
		});
		let menuLeft = $('header .header-wrap .left-wrap .bottom .main-menu').mapping({
			mobileWrapper: 'header .mobile-wrap',
			mobileMethod: 'prependTo',
			desktopWrapper: 'header .header-wrap .left-wrap .bottom',
			desktopMethod: 'appendTo',
			breakpoint: 1279.89
		});
		let menuRight = $('header .header-wrap .right-wrap .bottom .main-menu').mapping({
			mobileWrapper: 'header .mobile-wrap .main-menu',
			mobileMethod: 'insertAfter',
			desktopWrapper: 'header .header-wrap .right-wrap .bottom',
			desktopMethod: 'prependTo',
			breakpoint: 1279.89
		});
		let social = $('header .header-wrap .right-wrap .top .social-list').mapping({
			mobileWrapper: 'header .mobile-wrap',
			mobileMethod: 'appendTo',
			desktopWrapper: 'header .header-wrap .right-wrap .top',
			desktopMethod: 'appendTo',
			breakpoint: 1279.89
		});
	},
	menuMobile: () => {
		let btn = $('header .button-hambuger'),
			menuMobile = $('header .mobile-wrap'),
			header = $('header');
		btn.on('click', function () {
			$(this).toggleClass('active');
			menuMobile.toggleClass('active');
		});
	},
	toggleNavMenuMobile: () => {
		if ($(window).width() < 1280) {
			let btn = $('.mobile-wrap .main-menu>li.has-mega-menu>em');
			btn.on('click', function () {
				if ($(this).hasClass('active')) {
					$(this).parents('.mobile-wrap').find('.main-menu>li.has-mega-menu>.mega-menu').slideUp().prev().removeClass('active');
				} else {
					$(this).parents('.mobile-wrap').find('.main-menu>li.has-mega-menu>.mega-menu').slideUp().prev().removeClass('active');
					$(this).addClass('active').next().slideDown();
				}
			});

			let btnLink = $('.mobile-wrap .main-menu>li.has-mega-menu>a');
			btnLink.on('click', function (e) {
				e.preventDefault();
				if ($(this).next().hasClass('active')) {
					$(this).next().removeClass('active').parents('.mobile-wrap').find('.main-menu>li.has-mega-menu>.mega-menu').slideUp();
				} else {
					$(this).parents('.mobile-wrap').find('.main-menu>li.has-mega-menu>.mega-menu').slideUp().prev().removeClass('active');
					$(this).next().addClass('active').next().slideDown();
				}
			});

			let btnMegaChild = $('.mobile-wrap .main-menu>li.has-mega-menu .mega-menu-child>em, .mobile-wrap .main-menu>li.has-mega-menu .mega-menu-child>.mobile-no-touch');
			btnMegaChild.on('click', function (e) {
				e.preventDefault();
				if ($(this).parent().find('em').hasClass('active')) {
					$(this).parent().parent().parent().find('em').removeClass('active').next().slideUp();
				} else {
					$(this).parent().parent().parent().find('em').removeClass('active').next().slideUp();
					$(this).parent().find('em').addClass('active').next().slideDown();
				}
			});

		}
	},
	toggleSearchBox: () => {
		let btn = $('header .main-search'),
			btnClose = $('header .search-wrap .btn-close'),
			searchBox = $('header .search-wrap'),
			bodyClass = $('body')
		btn.on('click', function () {
			searchBox.toggleClass('active');
			disableScroll();
		});
		btnClose.on('click', function () {
			searchBox.toggleClass('active');
			enableScroll();
		});

	},
	disableScrollOpen: () => {

	},
	init: () => {
		header.scrollActive();
		header.mappingMenu();
		header.menuMobile();
		header.toggleNavMenuMobile();
		header.toggleSearchBox();
	}
}

let buttonChatbox = {
	scrollShowBackToTop: () => {
		let height = $(window).height();
		if ($(window).scrollTop() > height) {
			$('.backtotop').addClass('active')
		} else {
			$('.backtotop').removeClass('active')
		}
	},
	backToTop: () => {
		let btn = $('.backtotop');
		btn.on('click', function () {
			$('html, body').animate({
				scrollTop: 0
			}, 0)
		});
	},
	toggleShowChatBox: () => {
		let btn = $('.chatbox-fixed .icon'),
			listChatBox = $('.chatbox-fixed .chatbox-list');
		btn.on('click', function () {
			$(this).toggleClass('active');
			listChatBox.slideToggle();
		});
	},
	init: () => {
		buttonChatbox.scrollShowBackToTop();
		buttonChatbox.backToTop();
		buttonChatbox.toggleShowChatBox();
	}
}

let scrollNavSticky = {
	toggleNavMb: () => {
		let btn = $('.nav-scroll-section .nav-section .nav-mobile');
		btn.on('click', function () {
			$(this).toggleClass('active').next().slideToggle();
		});
	},
	scrollToSection: () => {
		let btnLink = $('.nav-scroll-section .nav-section .nav-list li a');
		btnLink.bind('click', function (event) {
			if (this.hash !== "") {
				event.preventDefault();
				var hash = this.hash;
				let header = $('header').outerHeight();
				let navMobile = $('.nav-scroll-section .nav-section .nav-mobile').outerHeight();
				$('html, body').animate({
					scrollTop: $(hash).offset().top - header - navMobile
				}, 0);
				if ($(window).width() < 1024) {
					$(this).parents('.nav-list').slideUp(1000)
				}
			};
		});
	},
	scrollCheckActive: () => {
		let scrollDistance = $(window).scrollTop();

		$('.nav-scroll-section').each(function () {
			let navScroll = $('.nav-scroll-section'),
				breakcrumbs = $('.global-breakcrumb'),
				navScrollPosTop = navScroll.position().top;
			navScrollPosTop <= scrollDistance ? (navScroll.addClass('active'), setTimeout(() => {
				navScroll.addClass('show')
			}, 100)) : navScroll.removeClass('active show');
		})

		$('[data-section]').each(function (i) {
			let headerHeight = $('header').outerHeight(),
				navMobile = $('.nav-scroll-section .nav-section .nav-mobile').outerHeight();

			let paddingTop = $(this).position().top - headerHeight - navMobile - 1;

			if (paddingTop <= scrollDistance) {
				$('.nav-scroll-section .nav-section .nav-list li.active').removeClass('active');
				$('.nav-scroll-section .nav-section .nav-list li').eq(i).addClass('active');

				// change text mobile
				let textActive = $('.nav-section .nav-list li.active a').text();
				$('.nav-scroll-section .nav-section .nav-mobile p').html(textActive);

				// lamp fixed left to right
				var currentleft = $('.nav-scroll-section .nav-section .nav-list li.active').position().left + "px";
				var currentwidth = $('.nav-scroll-section .nav-section .nav-list li.active').css('width');
				$('.lamp').css({
					"left": currentleft,
					"width": currentwidth
				});
			}

		});
	},
	init: () => {
		scrollNavSticky.toggleNavMb();
		scrollNavSticky.scrollToSection();
	}
}

function setBackgroundElement() {
	$('[setBackground]').each(function () {
		var background = $(this).attr('setBackground')
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-size": "cover",
			"background-position": "center center"
		});
	});
	$('[setBackgroundRepeat]').each(function () {
		var background = $(this).attr('setBackgroundRepeat')
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-repeat": "repeat"
		});
	});
}

function swiperInit() {
	let homeBanner = new Swiper('.home-banner .swiper-container', {
		lazy: {
			loadPrevNext: true,
		},
		preloadImages: false,
		speed: 1000,
		slidesPerColumnFill: "row",
		spaceBetween: 20,
		navigation: {
			nextEl: ".home-banner .button-next",
			prevEl: ".home-banner .button-prev",
		},
		pagination: {
			el: '.home-banner .swiper-pagination',
			clickable: true,
		},
	});

	let vid = document.getElementsByTagName("video")[0];
	if (vid) {
		let posVideo = $('.swiper-slide-video').index() + 1;
		vid.addEventListener("canplay", function () {
			homeBanner.autoplay.stop();
		}, true);
		vid.addEventListener("ended", function () {
			homeBanner.slideTo(posVideo, 1000, true);
			homeBanner.autoplay.start();
		}, true);
	};
	homeBanner.on('slideChange', function () {
		vid.pause();
		if (this.activeIndex === 0) {
			vid.play();
		}
	});

	let home1Slide = new Swiper('.home-1-section .swiper-container', {
		speed: 1000,
		slidesPerView: 3,
		spaceBetween: 10,
		slidesPerColumnFill: "row",
		navigation: {
			nextEl: ".home-1-section .button-next",
			prevEl: ".home-1-section .button-prev",
		},
		pagination: {
			el: '.home-1-section .swiper-pagination',
			clickable: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			1024: {
				slidesPerView: 4,
				spaceBetween: 10,
			},
			1280: {
				slidesPerView: 5,
				spaceBetween: 0,
			},
		},
	});
	let home4Slide = new Swiper('.home-4-section .swiper-container', {
		speed: 1000,
		spaceBetween: 30,
		autoHeight: true,
		slidesPerColumnFill: "row",
		navigation: {
			nextEl: ".home-4-section .button-next",
			prevEl: ".home-4-section .button-prev",
		},
		pagination: {
			el: '.home-4-section .swiper-pagination',
			clickable: true,
		},
	});
	let home5Slide = new Swiper('.home-5-section .swiper-container', {
		speed: 1000,
		spaceBetween: 30,
		slidesPerView: 1,
		slidesPerColumnFill: "row",
		navigation: {
			nextEl: ".home-5-section .button-next",
			prevEl: ".home-5-section .button-prev",
		},
		pagination: {
			el: '.home-5-section .swiper-pagination',
			clickable: true,
		},
		slideToClickedSlide: true,
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 2.5,
				spaceBetween: 10,
				centeredSlides: true,
				loop: true,
				autoplay: {
					delay: 5000,
				},
				roundLengths: true,
				loopAdditionalSlides: 30,
			},
		},
	});
	let home6Slide = new Swiper('.home-6-section .swiper-container', {
		speed: 1000,
		spaceBetween: 0,
		slidesPerView: 3,
		slidesPerGroup: 3,
		slidesPerColumnFill: "row",
		navigation: {
			nextEl: ".home-6-section .button-next",
			prevEl: ".home-6-section .button-prev",
		},
		breakpoints: {
			576: {
				slidesPerView: 3,
				slidesPerGroup: 3,
			},
			768: {
				slidesPerView: 4,
				slidesPerGroup: 4,
			},
			1024: {
				slidesPerView: 3,
				slidesPerGroup: 3
			},
			1280: {
				slidesPerView: 4,
				slidesPerGroup: 4,
			}
		},
	});
	let about5SlideThumbs = new Swiper('.about-5-section .main-slide-thumbs .swiper-container', {
		speed: 1000,
		spaceBetween: 30,
		slidesPerView: 2,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
		slidesPerColumnFill: "row",
		breakpoints: {
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 4,
			},
			1024: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 4,
			},
			1366: {
				slidesPerView: 5,
			}
		},
	});
	let about5Slide = new Swiper('.about-5-section .main-slide-top .swiper-container', {
		speed: 1000,
		spaceBetween: 30,
		slidesPerColumnFill: "row",
		navigation: {
			nextEl: ".about-5-section .button-next",
			prevEl: ".about-5-section .button-prev",
		},
		thumbs: {
			swiper: about5SlideThumbs,
		},
	});
	let about7Slide = new Swiper('.about-7-section .swiper-container', {
		loop: true,
		autoplay: {
			delay: 5000,
		},
		speed: 1000,
		spaceBetween: 10,
		slidesPerView: 2,
		slidesPerColumnFill: "row",
		observer: true,
		observeParents: true,
		navigation: {
			nextEl: ".about-7-section .button-next",
			prevEl: ".about-7-section .button-prev",
		},
		pagination: {
			el: '.about-7-section .swiper-pagination',
			clickable: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			1024: {
				slidesPerView: 4,
				spaceBetween: 20,
			},
			1280: {
				slidesPerView: 4,
				spaceBetween: 30,
			}
		},
	});
	let constructionDesignDetail1 = new Swiper('.construction-design-detail-1-section .swiper-container', {
		loop: true,
		autoplay: {
			delay: 5000,
		},
		speed: 1000,
		spaceBetween: 10,
		slidesPerView: 1,
		slidesPerColumnFill: "row",
		navigation: {
			nextEl: ".construction-design-detail-1-section .button-next",
			prevEl: ".construction-design-detail-1-section .button-prev",
		},
		slideToClickedSlide: true,
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 1.3,
				centeredSlides: true
			},
			1024: {
				slidesPerView: 1.8,
				centeredSlides: true
			},
			1280: {
				slidesPerView: 2.5,
				spaceBetween: 10,
				centeredSlides: true
			},
		},

	});
	let constructionDesignDetail2 = new Swiper('.construction-design-detail-2-section .swiper-container', {
		loop: true,
		speed: 1000,
		spaceBetween: 20,
		allowTouchMove: false,
		slidesPerView: 1,
		slidesPerColumnFill: "row",
		navigation: {
			nextEl: ".construction-design-detail-2-section .button-next",
			prevEl: ".construction-design-detail-2-section .button-prev",
		},
		// autoHeight: true,
	});
	let constructionDesignDetail3 = new Swiper('.construction-design-detail-3-section .swiper-container', {
		loop: true,
		speed: 1000,
		spaceBetween: 20,
		// allowTouchMove: false,
		slidesPerView: 1,
		slidesPerColumnFill: "row",
		navigation: {
			nextEl: ".construction-design-detail-3-section .button-next",
			prevEl: ".construction-design-detail-3-section .button-prev",
		},
	});
	let constructionDesignDetail5 = new Swiper('.construction-design-detail-5-section .swiper-container', {
		loop: true,
		autoplay: {
			delay: 5000,
		},
		speed: 1000,
		slidesPerView: 1,
		spaceBetween: 30,
		slidesPerColumnFill: "row",
		navigation: {
			nextEl: ".construction-design-detail-5-section .button-next",
			prevEl: ".construction-design-detail-5-section .button-prev",
		},
		pagination: {
			el: '.construction-design-detail-5-section .swiper-pagination',
			clickable: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			},
		},
	});
	let interiorDesign2Slide = new Swiper('.interior-design-2-section .swiper-container', {
		loop: true,
		autoplay: {
			delay: 5000,
		},
		speed: 1000,
		slidesPerView: 1,
		spaceBetween: 30,
		slidesPerColumnFill: "row",
		navigation: {
			nextEl: ".interior-design-2-section .button-next",
			prevEl: ".interior-design-2-section .button-prev",
		},
		pagination: {
			el: '.interior-design-2-section .swiper-pagination',
			clickable: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			},
		},
	});
	let interiorDesign9Slide = new Swiper('.interior-design-9-section .swiper-container', {
		loop: true,
		autoplay: {
			delay: 5000,
		},
		speed: 1000,
		slidesPerView: 1,
		spaceBetween: 30,
		slidesPerColumnFill: "row",
		navigation: {
			nextEl: ".interior-design-9-section .button-next",
			prevEl: ".interior-design-9-section .button-prev",
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			},
		},
	});
	let completeConstruction2Slide = new Swiper('.complete-construction-2-section .swiper-container', {
		// loop: true,
		autoplay: {
			delay: 5000,
		},
		speed: 1000,
		slidesPerView: 1,
		spaceBetween: 20,
		slidesPerColumnFill: "row",
		observer: true,
		observeParents: true,
		direction: 'horizontal',
		navigation: {
			nextEl: ".complete-construction-2-section .button-next",
			prevEl: ".complete-construction-2-section .button-prev",
		},
		breakpoints: {
			576: {
				slidesPerView: 2,

			},
			768: {
				slidesPerView: 2,
				slidesPerColumn: 2,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 30,
				slidesPerColumn: 2,
			},
			1280: {
				slidesPerView: 4,
				spaceBetween: 30,
				slidesPerColumn: 2,
			},
		},
	});
	let completeConstruction3Slide = new Swiper('.complete-construction-3-section .swiper-container', {
		loop: true,
		speed: 1000,
		slidesPerView: 1,
		spaceBetween: 30,
		slidesPerColumnFill: "row",
		observer: true,
		observeParents: true,
		direction: 'horizontal',
		allowTouchMove: false,
		navigation: {
			nextEl: ".complete-construction-3-section .button-next",
			prevEl: ".complete-construction-3-section .button-prev",
		},
	});
	let completeConstruction8SlideThumbs = new Swiper('.complete-construction-8-section .main-slide-thumbs .swiper-container', {
		loop: true,
		autoplay: {
			delay: 5000,
		},
		speed: 1000,
		spaceBetween: 10,
		slidesPerView: 2,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
		slidesPerColumnFill: "row",
		breakpoints: {
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 4,
				spaceBetween: 20,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			1280: {
				slidesPerView: 4,
				spaceBetween: 20,
			},
		},
	});
	let completeConstruction8Slide = new Swiper('.complete-construction-8-section .main-slide-top .swiper-container', {
		loop: true,
		autoplay: {
			delay: 5000,
		},
		speed: 1000,
		spaceBetween: 30,
		slidesPerColumnFill: "row",
		navigation: {
			nextEl: ".complete-construction-8-section .button-next",
			prevEl: ".complete-construction-8-section .button-prev",
		},
		thumbs: {
			swiper: completeConstruction8SlideThumbs,
		},
	});

	let quotesSpaceItem = $('.quotes-by-space-item .swiper-container');
	quotesSpaceItem.each(function (index) {
		$(this).addClass('quotes-by-space-item-slide-' + index);
		$(this).parent().find('.button-prev').addClass('button-prev-' + index);
		$(this).parent().find('.button-next').addClass('button-next-' + index);
		return new Swiper('.quotes-by-space-item-slide-' + index, {
			loop: true,
			autoplay: {
				delay: 5000,
			},
			speed: 1000,
			slidesPerView: 1,
			spaceBetween: 10,
			slidesPerColumnFill: "row",
			observer: true,
			observeParents: true,
			direction: 'horizontal',
			navigation: {
				nextEl: ".button-next-" + index,
				prevEl: ".button-prev-" + index,
			},
			breakpoints: {
				576: {
					slidesPerView: 2,
				},
				1280: {
					slidesPerView: 2.05,
					centeredSlides: true
				},
			},
		});
	});

	let bedroomSlider = new Swiper('.bedroom-banner-section .swiper-container', {
		speed: 1000,
		spaceBetween: 15,
		slidesPerView: 2,
		slidesPerGroup: 2,
		navigation: {
			nextEl: ".bedroom-banner-section .button-next",
			prevEl: ".bedroom-banner-section .button-prev",
		},
		pagination: {
			el: '.bedroom-banner-section .swiper-pagination',
			clickable: true,
		},
		slideToClickedSlide: true,
		breakpoints: {
			576: {
				slidesPerView: 3,
				slidesPerGroup: 3,
				spaceBetween: 15,
			},
			768: {
				slidesPerView: 4,
				slidesPerGroup: 4,
				spaceBetween: 20,
			},
			1024: {
				slidesPerView: 4,
				slidesPerGroup: 4,
				spaceBetween: 30,
			},
			1280: {
				slidesPerView: 5,
				slidesPerGroup: 5,
				spaceBetween: 30,
			},
		},
	});
}

function toggleFaqs() {
	$('[data-toggle-faqs="toggle"]').each(function () {
		$(this).find('.toggle-button').on('click', function () {
			$(this).toggleClass('active').next().slideToggle();
		})
	});
}

const moveHtml = {
	moveSection: () => {
		let elmSection = $('.elmSectionAppend');
		// console.log(elmSection.length);
		if (elmSection.length < 2) {
			$('.section-append').append(elmSection)
		} else {
			// elmSectionAppend
			$('.section-append').each(function () {
				let dataSectionId = $(this).data('section');
				// console.log(dataSectionId);
				$(this).append($('.elmSectionAppend-' + dataSectionId));
			})
		}
	},
	moveHtml: () => {
		let elmSection = $('.elmAppend');
		$('.div-append').append(elmSection)
	},
	init: () => {
		moveHtml.moveSection();
		moveHtml.moveHtml();
	}
}

function fbs_click(width, height) {
	let leftPosition, topPosition;
	leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
	topPosition = (window.screen.height / 2) - ((height / 2) + 50);
	let windowFeatures = "status=no,height=" + height + ",width=" + width + ",resizable=no,left=" + leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY=" + topPosition + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";
	let u = location.href;
	let t = document.title;
	window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(u) + '&t=' + encodeURIComponent(t), 'sharer', windowFeatures);
	return false;
}

function changeMaps() {
	let btn = $('.contact-section .brand-system-item');
	btn.on('click', function () {
		let dataIframe = $(this).data('iframe');
		$('.contact-section .main-maps iframe').attr('src', dataIframe)
	})
}

function getTitlePage() {
	let titlePage = $('ol.breadcrumb li a.active span').text();
	$('.wrap-form .form-group.getTitlePage input').val(titlePage);
	let titleNewsPage = $('.news-detail-section .main-title').text();
	$('.wrap-form .form-group.getNewsTitlePage input').val(titleNewsPage);
}

function autoCheck() {
	setTimeout(() => {
		$('.auto-check label').trigger('click')
	}, 500)
}

function loadProduct() {
	$("a.ajaxlink").on("click", function (e) {
		e.preventDefault();
		$("a.ajaxlink").parent().removeClass('active');
		$(this).parent().addClass('active');

		ProcessProductAjax($(this).attr('href'));
		return false;
	});
	setTimeout(() => {
		$('.box-nav-product .nav-product-list>li.has-dropdown .dropdown-list>li.active a').trigger('click');
	}, 1000);
}

function ProcessProductAjax(pageurl) {
	$.ajax({
		url: pageurl,
		data: {
			isajax: true
		},
		success: function (data) {
			$('.ajaxresponse').html($(data).find('.ajaxresponse').html());
			$(data).find('.newspager').insertAfter($('.ajaxresponse'));
			lazyloadFun();
			$("a.view-all").on("click", function (e) {
				e.preventDefault();
				LoadMoreProductAjax($(this).attr('href'));
				return false;
			});
		}
	});
}

function LoadMoreProductAjax(pageurl) {
	$.ajax({
		url: pageurl,
		data: {
			isajax: true
		},
		success: function (data) {
			$('.product-list').append($(data).find('.ajaxresponseloadmore').html());
			// console.log($(data).find('.button-view-all').length);
			if ($(data).find('.button-view-all').length == 1) {
				$('.button-view-all').html($(data).find('.button-view-all').html());
				$("a.view-all").on("click", function (e) {
					e.preventDefault();
					LoadMoreProductAjax($(this).attr('href'));
				});
			} else {
				$('.button-view-all').remove()
			}

			return false;
		}
	});
}

function loadGallery() {
	$(".branch-2-section .applicationspace-list, .bedroom-sample-list > .row").each(function () {
		$(this).find('> *').slice(0, 6).show();
	})
	let btn = $('.load-all-gallery');
	btn.on('click', function (e) {
		e.preventDefault();
		$(this).parents('.tabslet-content').find('.applicationspace-list > *:hidden').slice(0, 6).slideDown();
		$(this).parents('.bedroom-sample-list').find('> .row > *:hidden').slice(0, 6).slideDown();
		if ($('.applicationspace-list').length > 0) {
			if ($(this).parents('.tabslet-content').find('.applicationspace-list > *:hidden').length == 0) {
				console.log('hide 1');
				$(this).hide();
			}
		}
		if ($('.bedroom-sample-list').length > 0) {
			if ($(this).parents('.bedroom-sample-list').find('> .row > *:hidden').length == 0) {
				console.log('hide 2');
				$(this).hide();
			}
		}
	});
}

function addScriptScrollCustom() {
	if ($('body').hasClass('service-body')) {
		let scriptSrc = "<script src=\"/Data/Sites/1/skins/default/js/nicescroll.js\" charset=\"utf-8\"></script>"
		$('body').append(scriptSrc)
	}
}


function setCookie(cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

function deleteCookie(name) {
	document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function staffCookie() {
	$('.home-5-section .staff-slide-item .image').on('click', function () {
		let dataTabslet = $(this).data('tabslet');
		if (dataTabslet != "" && dataTabslet != null) {
			setCookie("datatabslet", dataTabslet, 365);
		}
		// console.log(dataTabslet);
	});
}

function checkStaffCookie() {
	let hashCached = window.location.hash
	// console.log(hashCached);
	if (hashCached != "")
		setTimeout(() => {
			$('.nav-section .nav-list li a[href=\"' + hashCached + '\"]').trigger('click')
		}, 1000)
	// console.log(hashCached != "");
	let dataTabslet = getCookie("datatabslet");

	if (dataTabslet != "")
		setTimeout(() => {
			$('.about-7-section .main-tabslet .tabslet-tab li a[href="' + dataTabslet + '"]').trigger('click');
		}, 1000);
}

function scrollYTabsletMb() {
	let btnCategory = $('.category-mobile .tabslet-category');
	let btnTabs = $('.category-mobile .tabslet-tab li a');
	btnCategory.on('click', function () {
		$(this).parents('.category-mobile').find('.tabslet-tab').animate({
			scrollLeft: '+=100'
		}, 300);
	});
	$('.category-mobile .tabslet-tab').each(function (index) {
		let widthTab = $(this).width();
		console.log(widthTab);
		var li_width = 0;
		$(this).addClass('tabslet-tab-' + index)
		$('.tabslet-tab-' + index).find('li').each(function () {
			li_width += $(this).innerWidth();
			if (li_width >= widthTab) {
				$(this).parents('.category-mobile').find('.icon').addClass('active')
			} else {
				$(this).parents('.category-mobile').find('.icon').removeClass('active')
			}
		});
	});
}

// lazy load 
// const targets = document.querySelectorAll('img');

// const lazyLoad = target => {
// 	const io = new IntersectionObserver((entries, observer) => {
// 		// console.log(entries)
// 		entries.forEach(entry => {
// 			console.log('😍');

// 			if (entry.isIntersecting) {
// 				const img = entry.target;
// 				const src = img.getAttribute('data-src');
// 				if (!!src) {
// 					console.log('>>>src>>', src)

// 					img.setAttribute('src', src);
// 					img.classList.add('fade');

// 					observer.disconnect();
// 				}

// 			}
// 		});
// 	});

// 	io.observe(target)
// };

// targets.forEach(lazyLoad);
// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {
	37: 1,
	38: 1,
	39: 1,
	40: 1
};

function preventDefault(e) {
	e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
	if (keys[e.keyCode]) {
		preventDefault(e);
		return false;
	}
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
	window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
		get: function () {
			supportsPassive = true;
		}
	}));
} catch (e) { }

var wheelOpt = supportsPassive ? {
	passive: false
} : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
	window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
	window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
	window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
	window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
	window.removeEventListener('DOMMouseScroll', preventDefault, false);
	window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
	window.removeEventListener('touchmove', preventDefault, wheelOpt);
	window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}


function bedroomInit() {
	$('.tabslet-categories').on('click', function () {
		if ($(window).width() < 1280) {
			$(this).toggleClass('active').next('.tabslet-tab').slideToggle();
		}
	});

	$('.bedroom-quotation-section .tabslet-tab li a').on('click', function () {
		let dataTabslet = $(this).attr('href');
		if (dataTabslet != "" && dataTabslet != null) {
			$(`.bedroom-sample-section .bedroom-sample-tab${dataTabslet}`).addClass('active').siblings().removeClass('active');
		}
	});
}
